<template>
  <div class="content">
    <base-header class="pb-7">
      <div class="row align-items-center py-4 d-md-none">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">账号资料</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2" v-loading="loading">
            <div slot="header" class="row align-items-center">
                <div class="col-12 d-flex align-items-center">
                    <span class="h3 mb-0 ls-2">账号资料</span>
                    <a class="btn btn-default ml-auto" href="javascript:;" @click="openChangePassword">
                        <span class="ni ni-key-25"></span> 修改登录密码
                    </a>
                </div>
            </div>
            <div class="px-4">
                <dl class="row">
                    <dt class="col-4 col-md-3 text-muted font-weight-light">账号名称</dt>
                    <dd class="col-8 col-md-9">{{ loginedUser.username }}</dd>
                    <dt class="col-4 col-md-3 text-muted font-weight-light">企业名称</dt>
                    <dd class="col-8 col-md-9">{{ profiles.company }}</dd>
                </dl>
                <dl class="row" v-if="!loginedUser.is_super">
                    <dt class="col-md-3 text-muted font-weight-light">负责人姓名</dt>
                    <dd class="col-md-9">{{ profiles.headuser_name }}</dd>
                    <dt class="col-md-3 text-muted font-weight-light">负责人邮箱地址</dt>
                    <dd class="col-md-9">{{ profiles.headuser_mail }}</dd>
                    <dt class="col-md-3 text-muted font-weight-light">负责人电话号码</dt>
                    <dd class="col-md-9">{{ profiles.headuser_phone }}</dd>
                    <hr>
                    <dt class="col-md-3 text-muted font-weight-light">主要联系人姓名</dt>
                    <dd class="col-md-9">{{ profiles.mainuser_name }}</dd>
                    <dt class="col-md-3 text-muted font-weight-light">主要联系邮箱地址</dt>
                    <dd class="col-md-9">{{ profiles.mainuser_mail }}</dd>
                    <dt class="col-md-3 text-muted font-weight-light">主要联系人电话号码</dt>
                    <dd class="col-md-9">{{ profiles.mainuser_phone }}</dd>
                </dl>
            </div>
        </card>
      </div>
    </div>
    <change-password v-if="loadChangePassword" :visible.sync="showChangePassword"></change-password>
  </div>
</template>
<script>
import { mapState } from "vuex";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
//import swal from "sweetalert2";

export default {
  components: {
    RouteBreadCrumb,
    'change-password': () => import('./Widgets/ChangePassword')
  },
  data() {
    return {
      loading: false,
      loadChangePassword: false,
      showChangePassword: false,
      profiles: {}
    }
  },
  computed: {
    ...mapState({
      loginedUser: (state) => state.user.user || {}
    })
  },
  methods: {
    openChangePassword() {
        if(!this.loadChangePassword) this.loadChangePassword = true; 
        this.showChangePassword = true;
    },
    fetchData() {
      this.loading = true;
      this.axios.get("profiles/0")
      .then((res) => {
        if (res.data.errcode == 0) {
            this.profiles = res.data.data;
            this.loading = false;
        }
        else {
            this.$notifyErr('未能成功加载数据', res.data.errmsg);
        }
      })
      .catch((e) => {
        console.error(e);
        this.loading = false;
      })
    }
  },
  mounted() {
    this.fetchData()
  }
};
</script>
<style scoped>
  .el-table::before { height: 0 }
</style>